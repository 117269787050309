import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import colors from '../../theme/colors';
import HowListContainer from '../../components/services/HowListContainer';

const serviceIntroductionData = {
    title: 'CI/CD DevOps',
    firstParagraph: 'Nos encargamos de los ambientes, las tareas de automatización y su mantenimiento para que vos te enfoques en la aplicación. Disminuí los tiempos entre una nueva funcionalidad y la puesta en producción de tus aplicaciones.',
    gatsbyImg: (<StaticImage src="../../images/ci-cd_icon.svg" alt="Nube" />),
    isButton: true,
    buttonText: 'Contactanos',
    buttonLinkTo: '/contact_us',
};

const howWeDoItProps = {
    style: {
        background: colors.darkViolet,
    },
    items: [
        {
            id: '1.',
            text: 'Te ayudamos a determinar la mejor arquitectura para un ambiente cloud.',
        },
        {
            id: '2.',
            text: 'Nuestros ingenieros implementarán las herramientas acordes a tus flujos de trabajo.',
        },
        {
            id: '3.',
            text: 'Trabajamos en conjunto con tu equipo de desarrollo.',
        },
        {
            id: '4.',
            text: 'Podemos ofrecerte una herramienta para versionado de código, en caso de que la necesites.',
        },
        {
            id: '5.',
            text: 'Compatible con cualquier plataforma Git de versionado como GitHub, GitLab y BitBucket.',
        },
    ],
};

const CiCdDevops = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <HowListContainer howWeDoItProps={howWeDoItProps} />
        <HomeConsultation />
    </Layout>
);

export default CiCdDevops;
